<template lang="pug">
  
</template>

<script>
import firebase from '@/modules/common/firebase';

// TODO dont use a vue component for this
export default {
  name: 'Logout',

  mounted() {
    firebase.auth().signOut().then(() => {
      this.$router.push({ name: 'home' });

      // TODO research why I need to reload. 
      //location.reload();
    }).catch((e) => {
      this.$notify({
        type: 'error',
        text: e.message,
      });
    });
  },
};
</script>
