import Vue from 'vue';
import App from './App.vue';
import router from './router';
import firebase from '@/modules/common/firebase';

const analytics = firebase.analytics();
const auth = firebase.auth();

import '@/plugins';
import VueRouter from 'vue-router';

if(process.env.VUE_APP_ENV === 'production') {
  import('@/thirdParty/usersnap');
}

Vue.config.productionTip = false;

// verifies that firebase has initialized
let app: any;
auth.onAuthStateChanged((user: any) => {
  if (user) {
    analytics.setUserId(user.uid);
  }

  if (app) return;

  app = new Vue({
    router: router as VueRouter,
    render: h => h(App),
  }).$mount('#app');
});