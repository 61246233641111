import firebase from '@/modules/common/firebase';
import { Plan } from '@/types';
import { END_POINTS } from '@/config'
import axios from 'axios';

const firestore = firebase.firestore();
const auth = firebase.auth();

axios.interceptors.response.use(res => res.data, res => Promise.reject(res.response.data));

export const getPlans = () =>
    firestore.collection('plans').get()
        .then(query => query.docs.map(docRef => ({ ...docRef.data(), id: docRef.id }) as Plan));

export const redirectToStripeCheckout = async ({ planId, isMonthlySubscription }: { planId: string, isMonthlySubscription: boolean }) => {
    const token = await auth.currentUser?.getIdToken();
    const headers = { ['Authorization']: `Bearer ${token}` };

    const res = await (axios.post(`${END_POINTS.stripe}/session`, { planId, isMonthlySubscription }, { headers }) as Promise<{ url?: string }>)

    if(res?.url) {
        location.replace(res.url)
    }

    return res;
}
