import firebase from '@/modules/common/firebase';

const firestore = firebase.firestore();

export const getIndustries = () => {
	return firestore.collection('industries').get().then(snapshots => {
		const industries = [];

		snapshots.forEach(snap => {
			const label = snap.data().label;

			industries.push({
				label,
				id: snap.id
			});
		});

		return industries;
	});
};

export const getUserIndustry = (user) => {
	return firestore.collection('users').doc(user.uid).get().then(snap => {
		if(snap.exists) {
			return snap.data().industry;
		}
	})
}

export const saveUserIndustry = (user, industry) => {
	return firestore.collection('users').doc(user.uid).set({industry}, {merge: true});
}

export default {
	getIndustries,
	getUserIndustry,
	saveUserIndustry
};
