import Vue from 'vue';
import VueRouter from 'vue-router';

import Access from '@/layouts/Access.view';
import Dashboard from '@/layouts/Dashboard.view';
import Logout from '@/modules/access/views/Logout.view';
import Profile from "@/modules/settings/components/Profile";
import Plans from "@/modules/settings/views/Plans";

Vue.use(VueRouter);

function loadView(module, view) {
  return () => import(/* webpackChunkName: '[request]' */ `@/modules/${module}/views/${view}.view.vue`);
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Dashboard,
      children: [
        { path: '/', name: 'home', redirect: {name: 'myTours'} },
        { path: 'tours', name: 'myTours', meta: { requiredAuth: true }, component: loadView('tours', 'MyTours') },
        { path: 'tours/create', name: 'createTour', meta: { requiredAuth: true }, component: loadView('tours', 'CreateTour') },
        { path: 'tours/:id', name: 'viewTour', meta: { requiredAuth: false }, component: loadView('tours', 'ViewTour') },
        { path: 'tours/:id/edit', name: 'editTour', meta: { requiredAuth: true }, component: loadView('tours', 'CreateTour') },
        { path: 'tours/:id/link', name: 'linkTour', meta: { requiredAuth: true }, component: loadView('tours', 'LinkTour') },
        { path: 'settings', meta: { requiredAuth: true }, component: loadView('settings', 'Settings'),
          children: [
            {path: '', redirect: 'profile'},
            {path: 'profile', component: Profile, name: 'settings'},
            {path: 'plans', component: Plans},
          ] 
        },
      ],
    }, {
      path: '/',
      name: 'access',
      component: Access,
      meta: { requiredAuth: false },
      children: [
        { path: '/login', name: 'login', component: loadView('access', 'Login') },
        { path: '/register', name: 'register', component: loadView('access', 'Register') },
        { path: '/forgot-password', name: 'forgotPassword', component: loadView('access', 'ForgotPassword') },
        { path: '/email-confirmation', name: 'emailConfirmation', component: loadView('access', 'EmailConfirmation') },
        { path: '/reset-password', name: 'resetPassword', component: loadView('access', 'ResetPassword') },
        { path: '/message/:id', name: 'message', component: loadView('access', 'Message') },
      ],
    },

    { path: '/logout', component: Logout, name: 'logout' },
    { path: '*', redirect: '/' },
  ],
  scrollBehavior: () => ({ x: 0, y: 0 }),
});


export default router;
