<template lang="pug">
  div
    nav-header
    router-view(class="my-5")
    my-footer

</template>

<script>
  import Tour from '@/modules/tours/components/Tour';
  import NavHeader from '@/modules/tours/components/NavHeader';
  import MyFooter from '@/modules/common/components/MyFooter.component'

  export default {
    name: 'Dashboard',

    data: () => ({
      drawer: null
    }),

    props: {
      source: String
    },

    components: {
      Tour,
      NavHeader,
      MyFooter
    },

    methods: {
      logout() {
        this.$router.push({name: 'logout'});
      },
      createTour() {
        this.$router.push({name: 'createTour'});
      },
      goToDashboard() {
        this.$router.push({name: 'dashboard'});
      }
    }
  }
</script>
