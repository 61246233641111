<template lang="pug">
	v-card
		v-card-text
			h1(id="profile") Cuenta
			small Aquí puedes modificar tus datos personales

		v-divider
		v-card-text
			v-form
				v-text-field(label="Nombre" v-model="$v.name.$model")
				v-text-field(label="Email" v-model="$v.email.$model")
				v-select(
					label="Industria" 
					item-text="label" 
					item-value="id" 
					:items="industries"  
					:loading="loadingIndustry"
					v-model="$v.industry.$model"
				)

				v-dialog(width="400" v-model="dialog")
					v-btn(slot="activator" class="d-block" round color="primary" dark) Reset Contraseña 
					v-card
						v-card-text
							h2 Reset Contraseña
							form 
								v-text-field(label="Password" type="password")
								v-text-field(label="Confirm password" type="password" v-model="$v.password.$model")
						v-card-actions
							v-btn(block color="primary" @click="updatePassword(password)") Reset
										
		v-card-actions
			v-spacer
			v-btn(color="secondary" :disabled="isDisabled" :loading="loading" @click="save") GUARDAR 

</template>

<script>
import {required, email} from 'vuelidate/lib/validators';
import queries from '@/modules/access/services/queries';
import firebase from '@/modules/common/firebase';

const analytics = firebase.analytics();

export default {
	name: 'Profile',
	data() {
		return {
			name: '',
			email: '',
			password: '',
			loading: false,
			dialog: false,
			industry: '',
			industries: null,
			loadingIndustry: false
		}
	},
	mounted() {
		const user = firebase.auth().currentUser;

		this.name = user.displayName;
		this.email = user.email

		queries.getIndustries().then(industries => {
			this.industries = industries;
		});

		this.loadingIndustry = true;
		queries.getUserIndustry(user).then(industry => {
			this.industry = industry;
			this.loadingIndustry = false;
		})
	},

	computed: {
		isDisabled() {
			return !(this.$v.name.$dirty || this.$v.email.$dirty || this.$v.industry.$dirty);
		},
		user() {
			return firebase.auth().currentUser;
		}
	},

	methods: {
		handleError(e) {
			this.$notify({
				title: 'Invalid',
				text: e.message,
				type: 'error'
			});

			this.dialog = false;

		},

		updatePassword(password) {
			firebase.auth().currentUser.updatePassword(password)
				.then(() => {
					this.$notify({
						type: 'success',
						text: 'Contraseña actualizada'
					});
					this.dialog = false;
				})
				.catch(this.handleError.bind(this));
		},

		save() {
			if(this.$v.invalid || this.isDisabled) {
				return 
			}

			let promises = [];

			if(this.$v.email.$dirty) {
				promises.push(this.user.updateEmail(this.email));
			} 
			
			if(this.$v.name.$dirty) {
				promises.push(this.user.updateProfile({displayName: this.name}));
			}

			if(this.$v.industry.$dirty) {
				const user = this.user;
				const promise = queries.saveUserIndustry(user, this.industry);
				promise.then(() => analytics.setUserProperties({industry: this.industry}));
				promises.push(promise);
			}

			this.loading = true;
			const setLoadingFalse = () => (this.loading = false);

			
			const request = Promise.all(promises);

			request.catch(this.handleError.bind(this));
			
			request
				.then(() => this.$v.$reset())
				.then(setLoadingFalse)
				.catch(setLoadingFalse);
		}
	},

	validations: {
		name: {
			required
		},
		email: {
			required,
			email
		},
		industry: {
			required
		},
		password: {

		}
	}
}
</script>

<style lang="less" scoped>

</style>

