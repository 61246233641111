import {auth, firestore, storage} from '@/modules/common/firebase';
import {getUser, notify} from '@/modules/common/services';
import { DEFAULT_PLAN_ID } from '@/config';
import Vue from 'vue';

const mixin = {
  data() {
    return {
      utils: {
        isLoggedIn() {
          return !!auth.currentUser;
        },
      },
    };
  },
  methods: {
    $notify(obj) {
      notify(obj);
    },
    async $getUser() {
      return getUser();
    },
    $getDb() {
      return firestore;
    },

    async $isPremium() {
      const user = await this.$getUser();

      return true || user && user.planId && String(user.planId) !== DEFAULT_PLAN_ID;
    },

    $getStorage(name) {
      const storageName = name && `gs://${name}`;
      return storage(storageName).ref();
    },
  },
};

Vue.mixin(mixin);
