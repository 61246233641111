<template lang="pug">
  v-snackbar(multi-line
            :timeout="timeout"
            :top="top"
            :bottom="bottom"
            :right="right"
            :left="left"
            :color="type"
            v-model="show")

    v-icon(color="white" class="mr-2" v-if="isError") mdi-close-circle
    v-icon(color="white" class="mr-2" v-else-if="isInfo") mdi-information
    v-icon(color="white" class="mr-2" v-else-if="isSuccess") mdi-check-circle

    span {{text}}

    v-btn(dark flat @click.native="close") Close
</template>

<script>
import {eventBus, events} from '@/modules/common/eventBus';

export default {
  name: 'Notifications',
  data() {
    return {
      show: false,
      title: '',
      text: '',
      type: ''
    }
  },

  mounted() {
    eventBus.$on(events.NOTIFY, this.notify);
  },

  computed: {
    isInfo() {
      return this.type === 'info';
    },
    isError() {
      return this.type === 'error';
    },
    isSuccess() {
      return this.type === 'success';
    }
  },

  props: {
    top: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: false
    },
    timeout: {
      type: Number,
      default: 6000
    }
  },
  methods: {
		notify(obj) {
      this.setType(obj.type);
      this.setTitle(this.type);
      this.text = obj.text;
      this.open();
    },

    open() {
      this.show = true;
    },

    close() {
      this.show = false;
    },

    setType(type) {
      if(['info', 'error', 'success'].indexOf(type) !== -1) {
        this.type = type;
      } else {
        this.type = 'info';
      }
    },

    setTitle(type) {
      switch (type) {
        case 'info':
          this.title = 'Information';
          break;
        case 'error':
          this.title = 'Error';
          break;
        case 'success':
          this.title = 'Success';
          break;
      }
    }
  }
}
</script>
