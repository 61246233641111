
































import Plan from "@/modules/settings/components/Plan.vue";
import { notify, getUser } from "@/modules/common/services";
import { getPlans, redirectToStripeCheckout } from "../services/api";
import { Component, Vue } from "vue-property-decorator";
import { FirestoreUser, Plan as IPlan } from "@/types";

@Component({
  name: "Plans",
  components: {
    Plan,
  },
})
export default class Plans extends Vue {
  isYearly: boolean = false;
  loading: boolean = false;
  user: FirestoreUser | {} = {};
  plans: IPlan[] = [];

  async mounted() {
    this.loading = true;
    try {
      this.plans = await getPlans();
      this.user = (await getUser()) as FirestoreUser;
    } catch (e) {
      this.handleError(e);
    }
    this.loading = false;
  }

  get isMonthly() {
    return !this.isYearly;
  }

  async subscribe(plan: IPlan) {
    this.loading = true;
    try {
      await redirectToStripeCheckout({
        planId: plan.id,
        isMonthlySubscription: this.isMonthly,
      });
    } catch (e) {
      notify({
        type: "error",
        text: e.message,
      });
    }

    notify({
      type: 'info',
      text: 'Tu subscripción finaliza al final del período'
    })
    this.loading = false;
  }

  handleError(e: any) {
    notify({
      title: "Invalid",
      text: e.message,
      type: "error",
    });
  }
}
