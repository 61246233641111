<template lang="pug">
	v-footer(dark absolute class="mt-5")
		v-card(
			flat
			tile
			class="white--text flex text-xs-center"
		)
			v-card-text()
				| &copy;{{currentYear}} — 
				strong Virtualitour
</template>

<script>
export default {
	name: 'Footer',
	computed: {
		currentYear() {
			return (new Date).getFullYear();
		}
	}
}
</script>

<style lang="less" scoped>
</style>

