import * as firebase from 'firebase/app';
import { firebaseConfig } from '@/config';

import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/auth';

firebase.initializeApp(firebaseConfig);
firebase.performance();

export const firestore = firebase.firestore();
export const analytics = firebase.analytics();
export const storage = firebase.storage();
export const auth = firebase.auth();

export default firebase;


