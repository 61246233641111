<template lang="pug">
  v-content(class="bg-virtualitour")
    v-layout(colum justify-center)
      v-flex
        div(class="img-logo mx-auto" @click="$router.push({name: 'home'})")

    v-container
      v-layout(row wrap justify-center)
        v-flex(xs12 sm6 md5 lg4 xl3)
          transition(:name="transitionName")
            router-view(class="view")

</template>

<script>

export default {
  name: 'Access',
  data() {
    return {
      transitionName: '',
    };
  },
  beforeRouteUpdate(to, from, next) {
    if(from.name ==='login' && to.name === "register") {
      this.transitionName = 'slide-right';

    } else if(from.name ==='register' && to.name === "login") {
      this.transitionName = 'slide-left';

    } else if(from.name ==='login' && to.name === "forgotPassword") {
      this.transitionName = 'slide-left';

    } else if(from.name ==='forgotPassword' && to.name === "login") {
      this.transitionName = 'slide-right';

    } else {
      this.transitionName = 'slide-left';
    }

    next()
  },
}
</script>

<style lang="less" scoped>
  @import '../less/global';

  .bg-virtualitour {
    background-image: @bg-pattern-virtualitour;
    background-repeat: repeat;
  }

  .img-logo {
    background-image: @img-logo;
    width: 300px;
    height: 300px;
    background-size: cover;
  }

  .view {
    transition: all .5s cubic-bezier(.55,0,.1,1);
  }

  .slide-right-leave-active, .slide-left-enter-active {
    opacity: 0;
    transform: translate(1000px, 0);
  }

  .slide-left-leave-active, .slide-right-enter-active {
    opacity: 0;
    transform: translate(-1000px, 0);
  }

</style>

