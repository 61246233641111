import firebase from '@/modules/common/firebase';
const auth = firebase.auth();
const analytics = firebase.analytics();

export default ( to, from, next ) => {
	const isLoggedIn = !!auth.currentUser;
	const isRoutePublic = !to.matched.some(record => record.meta.requiredAuth);

	if(!isRoutePublic && !isLoggedIn) {
		next(`/login?redirect=${to.path}`);
	} else {
		next();
		
		analytics.logEvent('screen_view', {
			screen_name: to.path
		});
	}
};
