



































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class HelloWorld extends Vue {
  @Prop() name!: string;
  @Prop() monthlyPrice!: number;
  @Prop() yearlyPrice!: number;
  @Prop() isMonthly!: boolean;
  @Prop() disabled!: boolean;
  @Prop() loading!: boolean;
  @Prop() description!: string;
  @Prop() icon!: string;
  @Prop() benefits!: string[];

  get isFree(): boolean {
    return Boolean(!this.yearlyPrice && !this.monthlyPrice);
  }

  click() {
    this.$emit("click");
  }
}
