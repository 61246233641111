const isProduction = process.env.VUE_APP_ENV === 'production';

const PROJECT_ID = process.env.VUE_APP_FIREBASE_PROJECT_ID;
const API_KEY = process.env.VUE_APP_FIREBASE_API_KEY;
const ANALYTICS_ID = process.env.VUE_APP_FIREBASE_ANALYTICS_ID;
const APP_ID = process.env.VUE_APP_FIREBASE_APP_ID;

export const USERSNAP_PROJECT_ID = process.env.VUE_APP_USERSNAP_PROJECT_ID;
export const IP_REGISTRY_API =  process.env.VUE_APP_IP_REGISTRY_API;

export const firebaseConfig = Object.freeze({
	apiKey: API_KEY,
	authDomain: `${PROJECT_ID}.firebaseapp.com`,
	databaseURL: `https://${PROJECT_ID}.firebaseio.com`,
	projectId: PROJECT_ID,
	storageBucket: `${PROJECT_ID}.appspot.com`,
	measurementId: ANALYTICS_ID,
	appId: APP_ID
});

export const buckets = Object.freeze({
	TEMP: `${PROJECT_ID}-temporal`,
	PERMANENT: `${PROJECT_ID}.appspot.com`
});

export const DOMAIN = isProduction ? 'https://virtualitour.es/app#' : `https://${firebaseConfig.authDomain}`; 
export const VIEWER = isProduction ? 'https://virtualitour.es/tours' : `https://${PROJECT_ID}.appspot.com`;


const END_POINT_URL = `https://us-central1-${PROJECT_ID}.cloudfunctions.net`;

export const END_POINTS = Object.freeze({
	stripe: `${END_POINT_URL}/stripe`, 
	tours: `${END_POINT_URL}/tours`
});

const VARS = [
  'VUE_APP_ENV',
  'VUE_APP_FIREBASE_PROJECT_ID',
  'VUE_APP_FIREBASE_API_KEY',
  'VUE_APP_FIREBASE_ANALYTICS_ID',
  'VUE_APP_FIREBASE_APP_ID',
  'VUE_APP_IP_REGISTRY_API',
  'VUE_APP_USERSNAP_PROJECT_ID'
];

VARS.forEach(varName => {
  if(!process.env[varName]) throw new Error(`Variable: "${varName}" is not defined`);
});

