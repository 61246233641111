<template lang="pug">
	v-toolbar(class="NavHeader" prominent color="white" fixed app)
		v-avatar(@click="goToDashboard" class="clickable")
			img(src="../../../assets/logo.png")

		v-toolbar-title(@click="goToDashboard" class="hidden-xs-only clickable ml-0")
			v-list
				v-list-tile
					v-list-tile-content
						v-list-tile-title Virtualitour
						v-list-tile-sub-title
							i(class="grey--text text--darken-2 subheading hidden-sm-and-down"): small
								|  Queremos darle una visión 360º a tu mundo


		v-spacer

		//- v-text-field(
		//- 	placeholder="Search..."
		//- 	background-color="#F5F5F5"
		//- 	prepend-inner-icon="mdi-magnify"
		//- 	color="primary"
		//- 	height="35"
		//- )

		//- v-spacer
		v-toolbar-items
			v-btn(flat class="hidden-xs-only" href="https://virtualitour.es/beneficios/") BENEFICIOS
			v-menu(bottom offset-y)
				template(slot="activator")
					v-btn(flat)
						| Guías
						v-icon() mdi-chevron-down

				v-list
					v-list-tile(href="https://virtualitour.es/guias/crear-recorrido-virtual/")
						v-list-tile-content
							v-list-tile-title ¿Cómo hacer un recorrido virtual?
					v-list-tile(href="https://virtualitour.es/guias/como-hacer-fotos-360/")
						v-list-tile-content
							v-list-tile-title ¿Cómo hacer fotos 360?


		v-divider(
			class="mx-3 hidden-xs-only"
			vertical
		)

		v-toolbar-items(v-if="!isLoggedIn")
			v-btn( @click="goToLogin" color="white" depressed) Iniciar Sesión

		v-menu(v-else bottom offset-y)
			div(slot="activator")
				v-avatar(class="hidden-xs-only")
					v-img(:src="userAvatar")

				v-icon() mdi-chevron-down

			v-list
				v-list-tile(avatar :to="{name: 'createTour'}")
					v-list-tile-avatar
						v-icon mdi-new-box
					v-list-tile-content
						v-list-tile-title Crear

				v-list-tile(avatar :to="{name: 'myTours'}")
					v-list-tile-avatar
						v-icon mdi-panorama
					v-list-tile-content
						v-list-tile-title Tours

				v-list-tile(avatar :to="{name: 'settings'}")
					v-list-tile-avatar
						v-icon mdi-settings
					v-list-tile-content
						v-list-tile-title Ajustes


				v-list-tile(avatar @click="logout")
					v-list-tile-avatar
						v-icon mdi-logout
					v-list-tile-content
						v-list-tile-title Salir
</template>

<script>
import md5 from 'md5';
import firebase from '@/modules/common/firebase';

export default {
	name: 'NavHeader',
	data() {
		return {
			user: null,
			links: [
				{title: 'BENEFICIOS', url: 'https://virtualitour.es/beneficios/' },
				{title: 'GUIAS', url: [
					{title: '¿Cómo hacer un recorrido virtual?', url: 'https://virtualitour.es/guias/crear-recorrido-virtual/'},
					{title: '¿Cómo hacer fotos 360?', url: 'https://virtualitour.es/guias/crear-recorrido-virtual/'}
				]},
			]
		}
	},
	computed: {
		isLoggedIn() {
			return !!this.user;
		},

		userAvatar() {
			if(this.user.photoURL) {
				return this.user.photoURL;
			} else {
				const hash = md5(this.user.email);
				return `https://www.gravatar.com/avatar/${hash}`;
			}
		}
	},

	mounted() {
		this.user = firebase.auth().currentUser;
	},

	methods: {
		logout() {
			this.$router.push({name: 'logout'});
		},

		goToDashboard() {
			this.$router.push({name: 'home'});
		},

		goToLogin() {
			this.$router.push({name: 'login'});
		},
		isArray: Array.isArray
	}
}
</script>

<style lang="less" scoped>
	.clickable {
		cursor: pointer;
	}
</style>

<style lang="less">

	.NavHeader {
		z-index: 5!important;
		.v-text-field > .v-input__control > .v-input__slot {
			&:before {
				content: none;
			}

			padding: 0 5px;
		}

		.v-text-field {
			padding-top: 17px;
		}
	}

</style>

