<template  lang="pug">
  div( id="app")
    v-app
      router-view
      notifications

</template>

<script>
import Notifications from '@/modules/common/components/Notifications.component';

export default {
  name: 'app',
  components: {
    Notifications,
  },
  mounted() {
  }
};
</script>
