<template lang="pug">
	v-card
		v-chip(v-if="promoted && !loading" color="indigo" text-color="white" class="Tour__promoted-tag") Promoted

		div(
			class="pa-2" 
			:class="loading ? '' : 'clickable'"
			@click="goTo"
		)
			v-img(
				v-if="!loading"
				class="Tour__img"
				:src="img"
				aspect-ratio="1.5"
			)

			div(v-else class="img-loading bg-loading")


		v-card-title(class="pt-0 pb-0 d-block")
			div(v-if="loading" class="bg-loading round-border two-lines mb-1") &nbsp;
			div(v-else class="two-lines")
				span: strong {{tour.name}}
				| &nbsp;-&nbsp;
				small(class="text-gray") 
					| {{tour.description}}


		v-card-text(class="pt-0") 
			span(v-if="!loading")
				span
					v-icon(color="indigo" small class="mr-1") mdi-eye
					| {{tour.views || 0}}
				span(class="ml-1 mr-1")
					v-icon(color="red" small class="mr-1") mdi-heart
					| {{tour.likes || 0}}

				small: i · {{tour.dateCreated | date | capitalize}}

			div(v-else class="bg-loading small-text round-border") &nbsp;

			v-menu(offset-x class="menu" v-if="options")
				v-btn(
					slot="activator"
					icon
					small
				)
					v-icon(small) mdi-dots-vertical

				v-list(dense)
					v-list-tile(
						v-for="(item, i) in menu" 
						:key="i" 
						@click="handleItem(item)" 
					)
						v-list-tile-avatar
							v-icon mdi-{{item.icon}}
						v-list-tile-sub-title {{item.title}}

</template>

<script>
import {distanceInWords} from 'date-fns'

export default {
	name: 'Tour',
	data() {
		return {
			menu: [
				{icon: 'cogs',   title: 'Editar', path: 'editTour'},
				{icon: 'link',   title: 'Enlazar', path: 'linkTour'},
				{icon: 'share',  title: 'Compartir',  action: () => this.emit('share', this.tour)},
				{icon: 'delete', title: 'Eliminar', action: () => this.emit('delete')},
			],

			imgLoaded: false
		}
	},
	props: {
		tour: {
			type: Object,
			default() {
				return {};
			}
		},

		loading: {
			type: Boolean,
			default: false
		},

		options: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		emit(name) {
			this.$emit(name, this.tour);
		},

		goTo() {
			this.$router.push({name: 'viewTour', params: {id: this.tour.id}});
		},

		handleItem(item) {
			if(item.path) {
				this.$router.push({name: item.path, params: {id: this.tour.id}});
			} else if (item.action) {
				item.action();
			}
		}
	},
	computed: {
		img() {
			return this.tour.coverPicUrl;
		},
		promoted() {
			return Math.random > 0.9;
		},

	},

	filters: {
		date: function (date) {
			const now = new Date();
			return distanceInWords(date, now);
		},
		capitalize: function (value) {
			if (!value) return ''
			value = value.toString()
			return value.charAt(0).toUpperCase() + value.slice(1)
		}
	}
}
</script>

<style lang="less" scoped>
	.Tour__promoted-tag {
		position: absolute;
		top: -16px;
		right: -13px;
		z-index: 1;
	}

	.menu {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.two-lines {
		height: 42px;
		overflow: hidden;
	}

	.clickable {
		cursor: pointer;
	}

	.bg-loading {
		background-color: #eee;
		animation-duration: 1.25s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: darkgray;
		background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
		background-size: 800px 104px;
	}

	@keyframes placeHolderShimmer{
		0%{
			background-position: -468px 0
		}
		100%{
			background-position: 468px 0
		}
	}

	.img-loading {
		height: 168px;

	}

	.small-text {
		width: 80%;
	}

	.round-border {
		border-radius: 5px;
	}

	.text-gray {
		color: #7d7777;
	}
</style>

