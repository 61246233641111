import { FirestoreUser } from "@/types";
import { firestore, auth } from "../firebase";

export const getUser = async () => {
    const authUser = auth.currentUser;

    if (authUser) {
        const userRef = await firestore.collection('users').doc(authUser.uid).get();
        const userData = userRef.data() as FirestoreUser;

        return { ...authUser, ...userData } as FirestoreUser;
    }
}

export default getUser;